define(['app'], function(app) {
  
  var _cancelOrderHelper = {

    _init: function() {
      _cancelOrderHelper._bindEvents();
    },

    _bindEvents: function() {
      app.element.on('click', _cancelOrderHelper._clickEvent,
        document.querySelectorAll('[data-change-cancel-qty="true"]'));
    },

    _clickEvent: function(event) {
      event = event || window.event;
      var target = event.target || event.srcElement;

      if (!target) {
        return;
      }

      var qtyChange = app.element.getAttribute('data-qty-change', target);
      var productId = app.element.getAttribute('data-product-id', target);
      var maxQty = app.element.getAttribute('data-max-qty', target);

      if (qtyChange && productId && maxQty) {
        if (qtyChange === 'decrease') _cancelOrderHelper._decreaseCancelQuantity(productId, maxQty);
        if (qtyChange === 'increase') _cancelOrderHelper._increaseCancelQty(productId, maxQty);
      }
    },

    _decreaseCancelQuantity: function(productId, maxQty) {
      // Need to keep this as an ID as it's a JSP form tag
      var value = document.getElementById('cancel-' + productId).value;

      if (!isNaN(value)) {
        var newValue = parseInt(value, 10) - 1;
        _cancelOrderHelper._setNewCancelQty(productId, newValue, maxQty);
      }
    },

    _increaseCancelQty: function(productId, maxQty) {
      // Need to keep this as an ID as it's a JSP form tag
      var value = document.getElementById('cancel-' + productId).value;

      if (!isNaN(value)) {
        var newValue = parseInt(value, 10) + 1;
        _cancelOrderHelper._setNewCancelQty(productId, newValue, maxQty);
      }
    },

    _setNewCancelQty: function(productId, newValue, maxQty) {
      if (newValue < 0) {
        newValue = 0;
      } else if (newValue > maxQty) {
        newValue = maxQty;
      }
      // Need to keep this as an ID as it's a JSP form tag
      document.getElementById('cancel-' + productId).value = newValue;
    }
  };

  if (document.querySelector('[data-component="myAccountCancelOrderHelper"]')) _cancelOrderHelper._init();

  return _cancelOrderHelper;
});
